<template>
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />
  <Breadcrumbs :param="breadcrumbs" />
  <div class="load-container" :class="{'load': isLoad}">
    <div v-if="data !== null" class="page-container">

      

      <div class="page-content" :class="{'page-content--fixed': !isDisabledSendReport}">


        <a 
          class="check-all" 
          :class="{'active': isSelectAll}" 
          @click="() => { 
            isSelectAll = !isSelectAll 
            data.tasks.forEach((item) => {
              item.isReport = isSelectAll ? true : false;
            })
          }">
          
          <template v-if="isSelectAll">
            <IconCross class="btn--ico--left" color="#ffffff"/>
            Снять выделение
          </template>
          <template v-if="!isSelectAll">
            <IconDoubleCheck class="btn--ico--left" color="#008DD2"/>
            Отметить все задачи
          </template>

        </a>


        <div class="block" v-for="(item) in data.tasks" :key="item.id" :ref="'task_' + item.id">
          <div class="block__header">
            <div class="badge badge--sm" :class="helpers.badgeDateParam(item.dateExpire, item.ttl).class">
              {{ helpers.badgeDateParam(item.dateExpire, item.ttl).text }}
            </div>
            <div
              class="status"
              :class="{
                'status--checked': item.isReport,
                'status--done': item.statusLocal == 'done',
                'status--failed': item.statusLocal == 'failed',
                'status--issue': item.statusLocal == 'issue',
                'status--empty': item.statusLocal == null,
              }"
              @click.prevent="() => {
                item.isReport = !item.isReport;
                if(!item.isReport) {
                  isSelectAll = false;
                }
              }">
              <div class="status__text" v-if="item.statusLocal !== null"> 
                <span v-if="item.statusLocal == 'done'"> Выполнено </span> 
                <span v-if="item.statusLocal == 'failed'"> Не выполнено </span> 
                <span v-if="item.statusLocal == 'issue'"> Есть проблемы </span> 
              </div>
              <div class="status__ico"></div>
            </div>
          </div>
          <div class="block__target" v-if="item.object && item.object !== null && item.object !== '' && item.object !== 'без указания по умолчанию'">
            <div class="block__additional">
              <TargetSmall color="#008DD2" />
              {{ item.object }}
            </div>
          </div>
          <div class="block__name">
            <div class="point" :class="helpers.statusParentParam(item.parentStatus).class" v-if="actionType == 'control'"></div>
            <router-link :to="nextLink(item.id)" v-html="formatedName(item.name)"></router-link>
          </div>

          <div class="block__footer" v-if="item.supplierComment !== null && item.supplierComment !== '' && item.supplierComment !== 'Задача по расписанию'">
            <div class="block__additional">
              <CommentSmall color="#AEC1CC" />
              {{ item.supplierComment }}
            </div>
          </div>

        </div>

        <transition name="slide">
          <a class="btn btn--default btn--lg btn--inline page-fixed" @click="sendReport($event)" v-if="data.tasks.length > 0 && !isDisabledSendReport"> 
            <img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
            <div class="btn--text"> Отправить отчёт ({{selectedCount}})</div>
            <div class="btn--loader"></div>
          </a>
        </transition>

      </div>
    </div>
    <div class="empty-msg" v-else> Данные отсутствуют </div>
  </div>
</template>

<script setup>
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';

  import TargetSmall from '@/components/icons/svg/TargetSmall.vue';
  import CommentSmall from '@/components/icons/svg/CommentSmall.vue';

  import IconDoubleCheck from '@/components/icons/svg/IconDoubleCheck.vue';
  import IconCross from '@/components/icons/svg/IconCross.vue';
</script>

<script>
  export default {
    
    name: 'PageSection',

    data: () => ({

      isSelectAll: false,

    	isLoad: true,
      isError: false,

      section: null,

      data: null,

      breadcrumbs: null, 
    }),
    
    computed: {
      formatedName () {
        return (name) => {
          return name.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
      },
      actionType () {
        return this.$route.meta.actionType;
      },
      house_id () {
        return this.$route.params.house_id;
      },
      section_id () {
        return this.$route.params.section_id;
      },
      pattern () {
        return this.$route.params.pattern;
      },
      user () {
        return this.$store.state.auth.user;
      },
      pageName () {
        return this.$route.meta.name;
      },
      nextPage () {
        return 'Page' + this.pageName + 'Task';
      },
      prevPage () {
        return 'Page' + this.pageName + 'House';
      },
      nextLink () {
        return (task_id) => {
          return {name: this.nextPage, params: {house_id: this.house_id, section_id: this.section_id, task_id: task_id}};
        }
      },
      prevLink () {
        return {name: this.prevPage, params: {house_id: this.house_id}};
      },
      isDisabledSendReport () {
        const data = [];
        this.data.tasks.forEach((task) => { data.push(task.isReport) });
        return data.includes(true) ? false : true;
      },
      selectedCount () {
        let count = 0;
        this.data.tasks.forEach((task) => {
          if(task.isReport) {
            count++
          }
        });
        return count;

      },
    },

    created () {
      Promise.all([this.loadItems()])
        .then(() => {
          this.beforeRender();
        })
        .catch((e)=> {
          console.log(e)
          this.isError = true;
        })
        .finally(() => {
          this.isLoad = false;
        })
    },


    methods: {
      
      beforeRender () {
        this.breadcrumbs = {
          current: {name: this.data.locTitle},
          prev: {name: this.data.address, link: this.prevLink},
        }

        if(this.$route.hash !== '') {

          const id = this.$route.hash.replace('#','');

          const block = this.$refs['task_' + id];

          if(block) {

            block[0].scrollIntoView({
              behavior: 'smooth',
              block: "center"
            });
          
          }

        }


      },

      async sendReport (e) {

        const btn = e.currentTarget;
        btn.classList.add('btn--load');

        const data = [];

        this.data.tasks.forEach((task) => {
          if(task.isReport) {
            data.push({id:task.id, status: task.statusLocal == null ? 'done' :  task.statusLocal});
          }
        });

        try {
          
          const r = await this.axios.put(this.$store.state.api_endpoint + '/checklist/' + this.actionType + '/' + this.user.id, {data: data});

          if(r.status == 200) {

            data.forEach((item) => {
              const idx = this.data.tasks.findIndex( (task) => { return task.id == item.id })
              this.data.tasks.splice(idx, 1);
            });


            

            // Отправили все задачи
            if(this.data.tasks.length == 0) {
              // Возвращаемся к списку секций
              return this.$router.push(this.prevLink);
            } else {
              // Остаёмся и смотрим оставшиеся задачи
              this.$refs.messageDialogue.show({
                message: 'Данные сохранены',
                okButton: 'Подтвердить',
              });
            }


            // // Вернуться в список?
            // const isConfirm = await this.$refs.confirmDialogue.show({
            //   message: 'Данные сохранены',
            //   okButton: 'Зкрыть',
            //   cancelButton: false,
            // });

            // // Согласился
            // if(isConfirm) {
            //   // Возвращаемся к списку секций
            //   this.$router.push(this.prevLink);
            // } else {
            //   // Не согласился, но мы отправили все задачи
            //   if(this.data.tasks.length == 0) {
            //     // Всё равно озвращаемся к списку секций
            //     return this.$router.push(this.prevLink);
            //   } else {
            //     // Остаёмся и смотрим оставшиеся задачи
            //   }
            // }

          }
          
        } catch (e) {
          this.$refs.messageDialogue.show({
            message: e.message,
            okButton: 'Подтвердить',
          });
        }

        btn.classList.remove('btn--load');
      },

      async loadItems () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/checklist/tasks/' + this.user.id + '/' + this.house_id + '/' + this.section_id + '?actionType=' + this.actionType  + '&active=true&locationPatternBase64=' + this.pattern);
        if(r.status == 200) {
          this.data = r.data;
          this.data.tasks.forEach( (task) => {
            task.isReport = false;
          })
        }
      },
    },

  }
</script>


<style scoped>
  .block {
    min-height: 120px;
    background-color: var(--cozh-white);
    border-radius: 8px;
    display: block;
    position: relative;
    padding: 18px;
  }
  .block:not(:last-child) {
    margin-bottom: 10px;
  }
  .block__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .block__name {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .block__name .point {
    margin-right: 10px;
    margin-top: 7px;
    margin-left: 5px;
  }
  .block__name a {
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0em;
    font-size: 20px;
  }
  .block__footer {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid rgba(233, 241, 244, 1);
  }
  .block__additional {
    font-size: 13px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0px;
    color: rgba(11, 40, 54, .5);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 23px;
    position: relative;
  }
  .block__additional img, .block__additional svg {
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .block__additional:not(:last-child) {
    margin-bottom: 13px;
  }
  .block__target {
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #E9F1F4;
  }
  .block__target .block__additional {
    color: var(--cozh-blue);
  }





  .slide-enter-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-active {
    transition: all 0.2s ease-out;
  }
  .slide-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }
  .slide-enter-from {
    transform: translateY(30px);
    opacity: 0;
  }


  .page-container {
    height: calc(100dvh - 213px);
    position: relative;
  }
  .page-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }
  .page-fixed:not(.disabled) {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }
  .page-content.page-content--fixed {
    padding-bottom: 65px;
  }


  .check-all {

    height: 36px;
    width: 100%;
    border-radius: 30px;
    border: 1px solid rgba(var(--cozh-blue-code), .35);
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #008DD2;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 10px;

  }

  .check-all.active {
    color: #fff;
    background-color: #008DD2;
    border-color: #008DD2;
  }



  @media (max-width: 767px) {}

</style>
